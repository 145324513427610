@media (min-width: 1280px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1230px;
    }
}
@media (min-width:992px){
    .navbar-expand-lg .navbar-nav .nav-link {
        padding: 0 1.5rem;
    }
}
@media(max-width:1024px){
    .testimonial-wraps{
        min-height: 550px;
    }
    .about-para {
        font-size: 17px;
    }
    .contact-card-head {
        font-size: 52px;

    }
}
@media (max-width:992px){
    .testimonial-wraps{
        min-height: 525px;
    }
    .navbar-collapse {
        background-color: #000;
        position: fixed;
        right: 0;
        left: 0;
        height: 100%;
        bottom: 0;
        top: 0;
        padding: 15px;
        transition:0.5s;
    }
    .navbar-collapse.hide{
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        visibility: hidden;
    }
    .navbar-collapse.Show{
        z-index: 1;
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
    }
    header .nav-link {
        color: var(--theme-color);
    }
    .close-icon {
        color: var(--theme-color);
        position: absolute;
        right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.8rem;
    }
    .navbar-nav {
        margin-top: 50px;
    }
    .Banner-main-head {
        font-size: 35px;
    }
    .Banner-sub-head {
        font-size: 25px;
    }
    .banner-para {
        font-size: 15px;
        padding: 15px 0rem;
    }
    .feature-head,.card-text{
        width:100%
    }
    .footer-top-banner-text {
        font-size: 34.5px;
        margin-bottom: 20px;
    }
    .mobile-only{
        display: block;
    }
    .desktop-only{
        display: none;
    }
}

@media (max-width:767px){
    .arrow-img{
        width:60px;
    }
    .submit {
        padding: 10px 15px;
        font-size: 14px;
    }
    .accesability-outer{
        justify-content: center;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
    }
    .accesible-btns{
        font-size: 13px;
    }
    .terms-div .about-para{
        font-size: 17px;
        }
    .testimonial-wraps{
        width:320px;
    }
    .contact-head,.what-we-do,.our-mission{
        font-size: 30px;
        text-align: center;
    }
    .contact-cards {
        padding: 25px;
        margin-bottom: 30px;
    }
    .contact-card-head{
        margin-bottom: 15px;
        font-size: 25px;
    }
   
    .social-icons img{
        width:30px;
    }
    .bottom-footer-img{
width:100px;
    }
    .footer-logo{
        width:100px;
    }
    .founder-position{
        padding-bottom: 0px;
        font-size: 17px;
    }
    .all-in-one-place {
        font-size: 20px;
        padding-top: 10px;

    }
    .testimnoial-para {
        font-size: 15px;
    }
    .testimonial-head{
        font-size: 20px;
    }
    .about-head {
        font-size: 30px;
        padding: 35px 0px;
    }
    .founder-name{
        font-size: 25px;
    }
    .about-para {
        font-size: 17px;
    }
    .our-section {
        text-align: left;
        font-size: 27px;
    }
    .abt-meet {
        font-size: 27px;
        padding: 35px 0px;
    }
    .Banner-main-head {
        font-size: 28px;
    }
    .Banner-sub-head {
        font-size: 21px;
    }
    .footer-border-top {
        flex-direction: column;
        width: 100%;
    }
    .banner-para {
        font-size: 14px;
        padding: 15px 0rem;
    }
    .navbar-nav .nav-link {
        font-size: 18px;
        margin-left:10px;
    }
    .form-control::placeholder{
        font-size: 14px;
    }
    .feature-card {
        padding: 0px 25px;
    }
    .copyright-footer {
        text-align: center;
    }
    .list-unstyled{
        justify-content: center;
        padding: 10px 0px;
    }
    .download-app-btn {
        padding: 10px 30px;
        font-size: 15px;
    }
    .feature-img{
        display: flex;
        justify-content: center;
        align-items: center;
        width:100%;
    }
    .footer-top-banner-text {
        font-size: 14.5px;
        margin-bottom: 20px;
    }
    .feature-head {
        font-size: 25px;
    }
    .card-text {
        font-size: 16px;
    }
}