:root {
    --theme-color: #FF4270;
    --header-color: #C4C4C4;
    --pink-btn: #FF4270;
    --black-bg: #000;
}

@font-face {
    src: url("../fonts/ProductSans-Bold.ttf");
    font-family: "ProductSans-Bold";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    src: url("../fonts/ProductSans-Medium.ttf");
    font-family: "ProductSans-Medium";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    src: url("../fonts/ProductSans-Regular.ttf");
    font-family: "ProductSans-Regular";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    src: url("../fonts/ProductSans-Light.ttf");
    font-family: "ProductSans-Light";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    color: var(--text-primary-color);
    font-family: "ProductSans-Regular";
    font-size: 1rem;
    background-color: var(--black-bg);
}

.navbar-nav .nav-link {
    color: var(--header-color);
    font-size: 14px;
}

.navbar-toggler {
    background-color: #fffbfbc9;
    border: 1px solid #ecdede;
}

.close-icon {
    display: none;
}

.top-header {
    min-height: 40px;
}
a.nav-link.active,a.nav-link.active:hover{
color: var(--theme-color);
}
.gradient-wrap {
    background-image: url("../images/pink-bg.png");
    background-size: contain;
    background-repeat: no-repeat;
}

/* Banner */
.Banner {
    padding: 50px 0px;
    position: relative;
    margin-bottom: 30px;
}

.Banner-main-head {
    font-size: 60px;
    color: #fff;
    text-align: center;
}

.Banner-sub-head {
    font-size: 40px;
    color: var(--theme-color);
    text-align: center;
}

.banner-para {
    font-size: 18px;
    color: #fff;
    text-align: center;
    padding: 30px 11rem;
}

.download-app-btn {
    text-align: center;
    border-radius: 10px;
    padding: 10px 60px;
    border: 2px solid var(--theme-color);
    display: inline-block;
    color: var(--theme-color);
    font-size: 18px;
    cursor: pointer;
}

.user-img {
    position: absolute;
    right: 0px;
    bottom: 50px;
    cursor: pointer;
}

.feature-wrap {
    background: #3A3A3A66;
    backdrop-filter: blur(4px)
}
.feature-wrap-pink{
    background: var(--theme-color);
    color: #000;
}

.left-border {
    border-left: 4px solid var(--theme-color);
}

.right-border {
    border-right: 4px solid var(--theme-color);
}

.feature-card {
    display: flex;
    justify-content: center;
}

.feature-head {
    font-family: "ProductSans-Bold";
    color: #fff;
    font-size: 40px;
    width: 340px;
    line-height: 40px;
    padding-bottom: 20px;
}

.card-text {
    color: #fff;
    font-size: 22px;
    width: 340px;
}
.feature-wrap-pink a.stretched-link{
    color: #000;
    cursor: pointer;
    font-size: 19px;
}
.feature-wrap-pink .card-text{
    color: #000;
    width:100%;
}
a.stretched-link {
    color: var(--theme-color);
    font-size: 18px;
    cursor: pointer;
}

.all-in-one-place{
    font-family: "ProductSans-Bold";
    color: #fff;
    font-size: 30px;
    padding-top: 60px;
    padding-bottom: 30px;
}
a.nav-link,a.nav-link:hover{
    color:#fff;
    font-size: 14px;
}
.list-unstyled li{
    color: #fff;
    font-size: 12px;
    padding: 0px 15px;
    cursor: pointer;
}
.list-unstyled  li a{
    text-decoration: none;
    color: #fff;
}
.copyright-footer{
    font-size: 12px;
    color: #fff;
}
 .footer-border-top{
border-top:1px solid var(--theme-color);
}
.Footer-Banner{
    background: #181818;
padding: 10px;
}
.text-pink{
    color: var(--theme-color);
}
.footer-top-banner-text{
    font-size: 35.5px;
    color: #fff;
    border-left: 3px solid var(--theme-color);
    padding-left: 15px;
}
.slick-next,.slick-prev{
display: none !important;
}
.testimonial-wraps{
    background: #3A3A3A66;
padding: 30px;
margin: 10px;
border-bottom: 3px solid var(--theme-color);
min-height: 450px;
width:650px;
}
.testimnoial-para{
    padding: 30px 0px;
    font-size: 23px;
    text-align: center;
    color:#fff;
    min-height: 300px;
}
.testimonial-name{
    color:var(--theme-color);
    font-size: 18px;
}
.testimonial-head{
    text-align: center;
    font-size: 30px;
    font-family: "ProductSans-Bold";
    color: #fff;
}
.Testimonials{
    padding-bottom: 5rem;
}
/* About */
.about-head{
    text-align: center;
    font-size: 60px;
    color: #fff;
    padding: 50px 0px;
}
.about-para{
    font-size: 24px;
    color: #fff;
    line-height: 30px;
}
.about-pink-large{
    padding: 20px 0px;
    font-size: 25px;
    font-style: oblique;
}
.our-section{
    text-align: center;
    color: #fff;
    font-size: 43px;
    padding: 20px 0px;
}
.abt-meet{
    text-align: center;
    font-size: 45px;
    padding: 50px 0px;
    color: #fff;
    padding-top: 100px;
}
.founder-name{
    color:var(--theme-color);
    font-size: 33px;
    padding-top: 30px;
    font-family: "ProductSans-Bold";
}
.founder-position{
    font-size: 21px;
    padding-bottom: 30px;
    color: #fff;
    font-style: oblique;
    font-family: "ProductSans-Medium";
}
.blend-img{
    filter: grayscale(1);
}

/* contact */
.contact-head{
    text-align: right;
    font-size: 50px;
    color: #fff;
    padding-right:30px;
    padding-bottom: 30px;
    padding-top: 30px;
}
.what-we-do{
    font-size: 45px;
    color: #fff;
    text-align: center;
    padding: 30px 0px;
    padding-bottom: 60px;
    padding-top: 60px;
}
.contact-cards{
    background-color: #000;
    border: 2px solid #FF427073;
    padding:50px;
    border-radius: 32px;
    margin-bottom: 90px;
    box-shadow: #E91E63 0px -1px 15px;
}
.contact-card-head{
    font-size: 45px;
    color: #fff;
    margin-bottom: 40px;
}
.our-mission{
    font-size: 45px;
    color: #fff;
    text-align: center;
    padding: 30px 0px;
}
.form-control{
    background: #101010;
    border: 1px solid var(--theme-color);
    height: 65px;
    border-radius: 10px;
    padding-left: 20px;
    font-family: "ProductSans-Medium";
    color:#fff;
    font-size: 20px;
}
.form-control::placeholder{
    font-family: "ProductSans-Medium";
    color:#fff;
    font-size: 20px;
    /* padding-left: 20px; */
}
.form-control:focus{
    color:#fff;
}
.submit{
background-color: var(--pink-btn);
display: inline-block;
padding: 15px 80px;
border-radius: 15px;
color: #fff;
font-size: 20px;
cursor: pointer;
outline: none;
box-shadow: none;
border: none;
}
.form-control:focus{
    background: #101010;
    border: 1px solid var(--theme-color);
    outline: none;
    box-shadow: none;
}
.mobile-only{
    display: none;
}
.content-section{
    color: #fff;
}
.table-list{
    padding-left: 1rem;
}
.table-list li,.table-list li a,.table-list li a:hover{
    font-size: 16px;
    text-decoration: none;
    color:#fff;
}
.terms-div .about-para{
font-size: 22px;
}
.mail-link,.mail-link:hover{
    color: #fff;
    /* text-decoration: none; */
}
.submit:disabled{
    pointer-events: none;
}
.accesibilty-settings{
background-color: #000;
color: #fff;
justify-content: center;
align-items: center;
display: flex;
}
.accesible-btns{
    text-align: center;
}
.about-image-outer{
    border-radius: 32px;
    box-shadow: #E91E63 0px -1px 15px;
}
.accesible-btns{
    background-color: #000;
    margin: 3px;
    border: none;
    color: var(--theme-color);
    border: 1px solid #575757;
}

/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
    padding: 1rem;
  }
  
  /* Modal Container */
  .modal-container {
    background-color: #2d2d2d;
    border-radius: 0.75rem;
    padding: 1.5rem;
    max-width: 28rem;
    width: 100%;
    color: white;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
  }
  
  /* Modal Heading */
  .modal-heading {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    color: white;
  }
  
  /* Input Container */
  .modal-input-container {
    margin-bottom: 1.5rem;
  }
  
  .modal-label {
    font-size: 0.875rem;
    color: #d1d1d1;
    margin-bottom: 0.5rem;
  }
  
  /* Password Input */
  .modal-input {
    width: 100%;
    padding: 0.75rem;
    background-color: #3e3e3e;
    border: 1px solid #565656;
    border-radius: 0.5rem;
    color: white;
    font-size: 1rem;
    outline: none;
  }
  
  .modal-input:focus {
    border-color: #e74c3c;
    box-shadow: 0 0 0 2px rgba(231, 76, 60, 0.5);
  }
  
  /* Consent Checkbox Container */
  .modal-checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .modal-checkbox {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.5rem;
  }
  
  .modal-checkbox-label {
    font-size: 0.875rem;
    color: #d1d1d1;
  }
  
  /* Buttons Container */
  .modal-buttons-container {
    display: flex;
    gap: 1rem;
    margin-top: 1.5rem;
  }
  
  /* Delete Button */
  .modal-delete-button {
    flex: 1;
    background-color: #e74c3c;
    padding: 0.75rem;
    border-radius: 0.5rem;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .modal-delete-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .modal-delete-button:hover:not(:disabled) {
    background-color: #c0392b;
  }
  
  /* Cancel Button */
  .modal-cancel-button {
    flex: 1;
    background-color: #7f8c8d;
    padding: 0.75rem;
    border-radius: 0.5rem;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .modal-cancel-button:hover {
    background-color: #616a6b;
  }
  
  /* Spinner Animation */
  .modal-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    animation: spin 1s linear infinite;
    margin-right: 0.5rem;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
 